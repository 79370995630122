import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './modules/page-not-found/components/page-not-found.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes =
[

  {
    path: '',
    loadChildren: './modules/home/home.module#HomeModule',
  },
  {
    path: 'about',
    loadChildren: './modules/about/about.module#AboutModule',
  },
  {
    path: 'business',
    loadChildren: './modules/business/business.module#BusinessModule',
  },
  {
    path: 'blog',
    loadChildren: './modules/blog/blog.module#BlogModule',
  },
  {
    path: 'blog/geelong-launch',
    loadChildren: './modules/blog/posts/geelong-launch/post.module#PostModule',
  },
  {
    path: 'cases',
    loadChildren: './modules/cases/business.module#BusinessModule',
  },
  {
    path: 'cases/launch-weekend',
    loadChildren: './modules/launch-weekend/business.module#BusinessModule',
  },
  {
    path: 'contact',
    loadChildren: './modules/contact/contact.module#ContactModule',
  },
  {
    path: 'partner',
    loadChildren: './modules/partner/contact.module#ContactModule',
  },
  {
    path: 'legal',
    loadChildren: './modules/policy/policy.module#PolicyModule',
  },
  {
    path: 'download',
    loadChildren: './modules/download/download.module#DownloadModule',
  },
  {
    path: 'get-zukaz',
    loadChildren: './modules/get-zukaz/get-zukaz.module#GetZukazModule',
  },
  {
    path: 'get-zukazbiz',
    loadChildren: './modules/get-zukazbiz/get-zukazbiz.module#GetZukazbizModule',
  },
  {
    path: 'brand-guidelines',
    loadChildren: './modules/brand-guidelines/brand-guidelines.module#BrandGuidelinesModule',
  },
  {
    path: 'BrandMaterial',
    loadChildren: './modules/brand-material/brand-material.module#BrandMaterialModule',
  },
  {
    path: 'underconstruct',
    loadChildren: './modules/under-construct/brand-material.module#BrandMaterialModule',
  },
  {
    path: 'promotions',
    loadChildren: './modules/hunts/policy.module#PolicyModule',
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      newestOnTop: true,
      tapToDismiss: true,
    }),
    RouterModule.forRoot(routes)
  ],
  providers: [CookieService],
})
export class AppModule { }
