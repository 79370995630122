import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { CanonicalService } from './../../canonical/services/canonical.service';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor
  (
  	private _service: CanonicalService,
    private meta: Meta,
    private titleService: Title,
  ) { }

  ngOnInit() {
  	this._service.updateTag({ rel: 'canonical', href: 'https://www.boostapp.com.au/' });
    this.titleService.setTitle('Zukaz | Page Not Found');
    this.meta.updateTag({ name: 'description', content: "Zukaz | Page Not Found" });
    this.meta.updateTag({ name: 'keyword', content: "Zukaz, Hunt, Real, Cash, Vouchers, Redeems, Virtual Reality" });
    this.meta.updateTag({ property: 'og:title', content: 'Zukaz | Page Not Found' });
    this.meta.updateTag({ property: 'og:description', content: "Zukaz | Page Not Found" });

    this.meta.updateTag({ name: 'twitter:title', content: 'Zukaz | Page Not Found' });
    this.meta.updateTag({ name: 'twitter:description', content: "Zukaz | Page Not Found" });
  }

}
